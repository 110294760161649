<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12">
        <h3>ตรวจสอบปริมาณสินค้า</h3>
        <hr class="mt-3">
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12">

        <div class="mt-4 warpper__contentBottom">
          <div>
            <v-card>
              <v-card-text>
<!--                <v-select-->
<!--                  v-model="sortBy"-->
<!--                  label="เรียงลำดับ"-->
<!--                  placeholder="เรียงลำดับ"-->
<!--                  item-text="text"-->
<!--                  item-value="value"-->
<!--                  :items="[{ text:'มากไปน้อย',value : 'DESC'},{text : 'น้อยไปมาก', value:'ASC'}]"-->
<!--                ></v-select>-->
<!--                <v-select-->
<!--                  v-model="stock_type"-->
<!--                  label="จำนวนสินค้า"-->
<!--                  placeholder="จำนวนสินค้า"-->

<!--                  item-text="text"-->
<!--                  item-value="value"-->
<!--                  :items="[-->
<!--                    {text : 'ทั้งหมด' , value:'all'},-->
<!--                    {text : 'สินค้ามี่จำนวนมาก' , value: 1},-->
<!--                    {text : 'สินค้าใกล้จะหมด' , value: 2},-->
<!--                    {text : 'สินค้าหมด' , value: 3}-->
<!--                    ]"-->
<!--                ></v-select>-->
<!--                <v-select-->
<!--                  v-model="brand_id"-->
<!--                  label="ยี่ห้อ"-->
<!--                  placeholder="เลือกยี่ห้อ"-->
<!--                  item-value="phone_brand_id"-->
<!--                  item-text="phone_brand_name"-->
<!--                  :items="brands"-->
<!--                  @change="changeBrand"-->
<!--                ></v-select>-->
<!--                <v-select-->
<!--                  :disabled="brand_id ==null"-->
<!--                  v-model="model_id"-->
<!--                  label="รุ่น"-->
<!--                  placeholder="เลือกรุ่น"-->
<!--                  item-value="phone_model_id"-->
<!--                  item-text="phone_model_name"-->
<!--                  :items="models"-->
<!--                ></v-select>-->
<!--                <v-select-->
<!--                  v-model="stock_type"-->
<!--                  label="จำนวนสินค้า"-->
<!--                  placeholder="จำนวนสินค้า"-->

<!--                  item-text="text"-->
<!--                  item-value="value"-->
<!--                  :items="[-->
<!--                    {text : 'ทั้งหมด' , value:'all'},-->
<!--                    {text : 'สินค้ามี่จำนวนมาก' , value: 1},-->
<!--                    {text : 'สินค้าใกล้จะหมด' , value: 2},-->
<!--                    {text : 'สินค้าหมด' , value: 3}-->
<!--                    ]"-->
<!--                ></v-select>-->
                <v-text-field
                  v-model="search"
                  label="ค้นหาจากชื่อ,ยี่ห้อ,รุ่น,รหัสสินค้า"
                  placeholder="ค้นหาจากชื่อ,ยี่ห้อ,รุ่น,รหัสสินค้า"
                ></v-text-field>
                <v-btn
                  :disabled="(!brand_id && !model_id  && !search)"
                  @click="getProduct"
                  class="mt=2 primary">
                  ค้นหา
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
          <div class="mt-10">
            <p class="text-center">แสดง 1 ถึง {{ page }} จาก {{ length_page }} ผลลัพธ์</p>
            <!-- <a @click="clickFirstPage">หน้าแรก</a> -->
            <v-pagination
              @input="changePage"
              v-model="page" :length="length_page" total-visible="7"></v-pagination>
            <!-- <a @click="clickLastPage">หน้าสุดท้าย</a> -->
          </div>
        </div>
      </v-col>
      <v-col
        v-for="(item,index) in products"
        md="3"
        sm="4"
        cols="6">
        <v-card>
          <v-card-text
            @click="showDetail(item)"
            style="cursor: pointer">

            <div v-if="item.product_amount === '0' ||  !item.product_amount"
                 class="product-label-low">
              <span>สินค้าหมด</span>
            </div>
            <div v-if="item.product_amount > 0  &&  item.product_amount <= 100"
                 class="product-label-fair">
              <span>สินค้าใกล้จะหมด</span>
            </div>

            <div v-if="item.product_amount > 100" class="product-label-much">
              <span>สินค้ามีจำนวนมาก</span>
            </div>
            <v-img
              width="100%"
              height="220"
              :src="item.image_path"
              :alt="item.image_path"
            ></v-img>
          </v-card-text>
          <v-card-text>
            <p>{{ item.product_name }}</p>
            <p>รหัสสินค้า : {{ item.product_code }}</p>
            <p>ยี่ห้อ : {{ item.brand ? item.brand.phone_brand_name : '-' }}</p>
            <p>รุ่น : {{ item.phone_model ? item.phone_model.phone_model_name : '-' }}</p>
            <p>ราคาขาย :{{ item.price ? item.price.product_price : '-' }}</p>
            <p style="color:rgb(227, 86, 83)">ปริมาณสินค้า : {{ item.product_amount || 0 }} /
              ชิ้น</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ editedItem.title }}</span>
        </v-card-title>
        <v-card-text
          @click="showDetail(item)"
          style="cursor: pointer">

          <div v-if="editedItem.product_amount === '0' ||  !editedItem.product_amount"
               class="product-label-low">
            <span>สินค้าหมด</span>
          </div>
          <div v-if="editedItem.product_amount > 0  &&  editedItem.product_amount <= 100"
               class="product-label-fair">
            <span>สินค้าใกล้จะหมด</span>
          </div>

          <div v-if="editedItem.product_amount > 100" class="product-label-much">
            <span>สินค้ามีจำนวนมาก</span>
          </div>
          <v-img
            width="100%"
            height="300"
            :src="editedItem.image_path"
            :alt="editedItem.image_path"
          ></v-img>
        </v-card-text>
        <v-card-text>

          <p>{{ editedItem.product_name }}</p>
          <p>รหัสสินค้า : {{ editedItem.product_code }}</p>
          <p style="color:rgb(227, 86, 83)">จำนวนคงเหลือ : {{ editedItem.product_amount || 0 }} /
            ชิ้น</p>

          <p>รายละเอียด :
            {{ editedItem.product_detail && editedItem.product_detail != 'null' ? editedItem.product_detail : "-" }}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!--        <v-btn color="error" text @click="closeAddProduct">-->
          <!--          Cancel-->
          <!--        </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.product-label-much {
  z-index: 2;
  position: absolute;
  padding: 5px 15px;
  background-color: green;
  color: white;
}

.product-label-fair {
  z-index: 2;
  position: absolute;
  padding: 5px 15px;
  background-color: #fcc044;
  color: white;
}

.product-label-low {
  z-index: 2;
  position: absolute;
  padding: 5px 15px;
  background-color: #e55151;
  color: white;
}
</style>
<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '@/axios.service'
import instance_product from '@/services/product'
import instance_user from '@/services/userManage'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'

export default {
  components: {},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    page: 1,
    per_page: 12,
    length_page: 1,
    search : '',
    // sortBy : { text : "มากไปน้อย" , value : 'DESC'},
    // stock_type : { text : "ทั้งหมด" , value : 'all'},
    products: [],
    brands: [],
    models: [],
    brand_id: null,
    model_id: null,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'ลำดับ',
        value: 'index'
      },
      {text: 'รูป/ภาพ', value: 'image'},
      {text: 'หัวข้อข่าวสาร', value: 'title'},
      {text: 'เผยแพร่', value: 'labelStatus'},
      {text: 'วันที่อัพเดท', value: 'updated_at'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status'},
      {text: 'สร้างโดย', value: 'employee.employee_name'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
    defaultItem: {
      file: null,
      status: '',
      title: '',
      employee: '',
      updated_at: '',
    },
  }),
  validations() {
    return {
      editedItem: {
        title: {required},
        content: {required},
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ข่าวสาร' : 'แก้ไข ข่าวสาร'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getBrand();
  },

  methods: {
    changePage() {
      this.getProduct()
    },
    showDetail(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    getBrand(){
      this.$store.state.isLoading = true
      instance_user.getBrand().then(res => {
        this.brands = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    changeBrand(){
      this.$store.state.isLoading = true
      instance_user.get_model(this.brand_id).then(res => {
        this.models = res.data.data
        this.model_id = null;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    getProduct() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search ? this.search : '',
        brand_id : this.brand_id ? this.brand_id : '',
        model_id : this.model_id ? this.model_id : '',
        page: this.page
        // stock_type: this.stock_type.value ? this.stock_type.value : this.stock_type,
        // sortBy: this.sortBy.value ? this.sortBy.value : this.sortBy,
      }
      instance_product.getUserProduct(data).then(res => {
        this.products = res.data.data.data
        this.length_page = (res.data.data.last_page);
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
